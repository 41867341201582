import { template as template_fe6234d0815048aca69de622353b0a64 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fe6234d0815048aca69de622353b0a64(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
