import { template as template_c57d7d0e556c424fa09d0498060de265 } from "@ember/template-compiler";
const FKLabel = template_c57d7d0e556c424fa09d0498060de265(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
