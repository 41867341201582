import { template as template_c6e7a6ff4ed44e2b89910adb4b8dd9f6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c6e7a6ff4ed44e2b89910adb4b8dd9f6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
